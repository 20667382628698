@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.loading-container {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.Loading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  animation: fadeInOut 3s ease-in-out forwards;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.Loading-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInOut 4s ease-in-out forwards;
}

.Loading-title {
  font-family: 'Pacifico', cursive;
  font-size: 90px;
  color: rgb(250, 209, 5);
  margin: 0;
  margin-bottom: 1px;
  animation: fadeInOut 4s ease-in-out forwards;
}

.Powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  font-size: 14px;
  color: black;
  animation: fadeInOut 4s ease-in-out forwards;
}

.Powered-by h4 {
  margin-top: 23px;
}

.Gemini-logo {
  width: 90px;
  animation: fadeInOut 3.5s ease-in-out forwards;
}
