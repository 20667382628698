.contribute-container {
    padding: 20px;
}

.top-contributors {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.contributors-list {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.contributor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contributor-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 5px;
}

.points {
    font-weight: bold;
    color: #fad105;
}

.contribution-posts {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.add-post-button {
    background-color: #fad105;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-form {
    margin-top: 10px;
}

.post-form textarea,
.post-form input {
    width: 95%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 10px;
}

.post-form textarea {
    height: 100px;
}

.post-form-buttons {
    display: flex;
    justify-content: flex-end;
}

.post-form-buttons button {
    margin-left: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.post-form-buttons button:first-child {
    background-color: #ccc;
}

.post-form-buttons button:last-child {
    background-color: #fad105;
}

.contributions-list {
    margin-top: 20px;
}

.contribution-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.contribution-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.contribution-card button {
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    margin-top: 10px;
}

.dropdown-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.expand-button {
    background-color: #f0f0f0 !important;
    border: 1px solid #ddd !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    padding: 8px 12px !important;
}

.expand-button svg {
    margin-left: 5px;
}

.contributors-dropdown,
.messages-dropdown {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.contributors-dropdown .contributor {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.contributors-dropdown .contributor-avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.messages-list {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.message-avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.message-content {
    flex: 1;
}

.message-sender {
    font-weight: bold;
    margin-right: 5px;
}

.message-input {
    display: flex;
}

.message-input input {
    flex: 1;
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.message-input button {
    padding: 5px 10px;
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.city-issues {
    margin-bottom: 20px;
}

.city-issues input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
}

.city-issues button {
    padding: 10px 15px;
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.issues-result {
    margin-top: 15px;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 8px;
    white-space: pre-line; 
}

.issues-result h3 {
    margin-bottom: 10px;
}

.issues-result p {
    margin-bottom: 10px;
}

.delete-button {
    background-color: #ff4d4d !important;
    color: white;
    margin-left: auto;
}

.contributed {
    background-color: #4CAF50 !important;
    color: white;
}

.my-points {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.my-points h3 {
    margin-bottom: 10px;
}

.points-display {
    display: flex;
    align-items: center;
    justify-content: center;
}

.points-display .user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
}

.points-display span {
    margin-right: 10px;
}

.points-display .points {
    font-weight: bold;
    color: #fad105;
}

/* Dark mode styles */
.dark-mode .my-points {
    background-color: #2a2a2a;
    color: #ffffff;
}

.dark-mode .contribute-container {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-mode .top-contributors,
.dark-mode .contribution-posts,
.dark-mode .contribution-card,
.dark-mode .contributors-dropdown,
.dark-mode .messages-dropdown {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dark-mode .post-form textarea,
.dark-mode .post-form input,
.dark-mode .message-input input {
    background-color: #3a3a3a;
    color: #ffffff;
    border-color: #4a4a4a;
}

.dark-mode .add-post-button,
.dark-mode .post-form-buttons button:last-child,
.dark-mode .contribution-card button,
.dark-mode .message-input button {
    background-color: #fad105;
    color: #000000;
}

.dark-mode .expand-button {
    background-color: #3a3a3a !important;
    border-color: #4a4a4a !important;
    color: #ffffff;
}

.dark-mode .city-issues input {
    background-color: #3a3a3a;
    color: #ffffff;
    border-color: #4a4a4a;
}

.dark-mode .city-issues button {
    background-color: #fad105;
    color: #000000;
}

.dark-mode .issues-result {
    background-color: #2a2a2a;
    color: #ffffff;
}

.dark-mode .delete-button {
    background-color: #ff4d4d !important;
    color: white;
}

.dark-mode .contributed {
    background-color: #4CAF50 !important;
    color: white;
}

@media (max-width: 768px) {
    .contributors-list {
      flex-direction: column;
      align-items: center;
    }
  
    .contributor {
      margin-bottom: 15px;
    }
    .post-form textarea,
  .post-form input {
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }

  .post-form-buttons {
    flex-direction: column;
  }

  .post-form-buttons button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .contribute-container {
    padding: 10px;
  }

  .top-contributors,
  .contribution-posts {
    padding: 10px;
  }
  .city-issues {
    display: flex;
    flex-direction: column;
  }

  .city-issues input,
  .city-issues button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}