@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

#CompleteProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

#CompleteProfile.dark-mode {
  background-color: #1a1a1a;
  color: #f0f0f0;
}

#CompleteProfile-header {
  text-align: center;
  width: 100%;
  max-width: 350px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

#CompleteProfile.dark-mode #CompleteProfile-header {
  background-color: #2a2a2a;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

#CompleteProfileTitleContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

#Title {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  color: rgb(250, 209, 5);
  margin: 0;
  margin-left: 4px;
  margin-bottom: 10px;
}

form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

form div {
  margin-bottom: 10px;
  width: 100%;
}

form input, form select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

#CompleteProfile.dark-mode form input,
#CompleteProfile.dark-mode form select {
  background-color: #3a3a3a;
  color: #f0f0f0;
  border-color: #555;
}

form button {
  padding: 10px 20px;
  background-color: rgb(250, 209, 5);
  border: none;
  color: white;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

form button:hover {
  background-color: rgb(198, 165, 5);
}

#input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

#input-container input {
  flex: 1;
  padding-right: 30px;
}

#input-container input.error {
  border: 2px solid red;
}

select#gender {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

#CompleteProfile {
  transition: opacity 0.5s ease-out;
}

#CompleteProfile.success #CompleteProfile-header > *:not(#success-message) {
  display: none;
}

#success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#welcome-text {
  font-size: 24px;
  text-align: center;
  animation: flyIn 0.5s ease-out forwards;
  opacity: 0;
  transform: translateY(50px);
}

@keyframes flyIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#loading-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid rgb(250, 209, 5);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

#avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#avatar-container > div {
  border-radius: 50%;
  overflow: hidden;
}

.theme-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #fad105;
  transition: color 0.3s;
}

#CompleteProfile.dark-mode .theme-toggle {
  color: #fad105;
}

#validation-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

#lt {
  font-size: 15px;
}


#react-select-container {
  width: 100%;
}

#react-select__control {
  min-height: 38px !important;
  height: 38px;
}

#react-select__indicators {
  height: 38px;
}

#react-select__indicator {
  padding: 0 8px !important;
}

#react-select__value-container {
  height: 38px;
  padding: 0 8px !important;
}

#react-select__placeholder {
  color: #aaa;
}

#react-select__input {
  color: #333;
}

#CompleteProfile.dark-mode #react-select__input {
  color: #f0f0f0;
}

#react-select__menu {
  z-index: 2;
}

#react-select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

@media (max-height: 600px) {
  #CompleteProfile {
    height: auto;
    min-height: 100vh;
  }

  form {
    max-height: none;
  }
}