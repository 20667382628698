@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.Login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.Login.dark-mode {
    background-color: #1a1a1a;
    color: #ffffff;
}

.Login-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginTitleContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-bottom: 20px;
}

.Title {
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    color: rgb(250, 209, 5);
    margin: 0;
    margin-left: 4px;
    margin-bottom: 10px;
}

.lt {
    color: #333;
}

.dark-mode .lt {
    color: #fff;
}

.google-login {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    max-width: 300px;
    height: 44px;
    overflow: hidden;
}

.dark-mode .google-login {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.google-logo {
    width: 20px;
    margin-right: 10px;
}

.google-login:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.dark-mode .google-login:hover {
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.google-login span {
    position: relative;
    z-index: 1;
    color: black;
}

.dark-mode .google-login span {
    color: white;
}

.separator {
    width: 100%;
    max-width: 300px;
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}

.dark-mode .separator {
    border-top-color: #3a3a3a;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form div {
    margin-bottom: 10px;
    width: 100%;
}

form input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #726c6c;
    border-radius: 5px;
}

.dark-mode form input {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
    color: white;
}

form button {
    padding: 10px 20px;
    background-color: rgb(250, 209, 5);
    border: none;
    color: white;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

form button:hover {
    background-color: rgb(198, 165, 5);
}

.signup-text {
    margin-top: 20px;
}

.signup-text a {
    color: rgb(250, 209, 5);
    text-decoration: none;
}

.accessibility-controls {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
}

.theme-toggle
{
  font-size: 24px;
    cursor: pointer;
    color: #fad105;
    margin-left: 10px;
}
.speech-toggle2 {
    font-size: 24px;
    cursor: pointer;
    color: #fad105;
    margin-right: 60px;
    margin-top: 21px;
}

.speech-toggle2.active {
    color: #00ff00;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid rgb(250, 209, 5);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dark-mode .loading-spinner {
    border-color: #2a2a2a;
    border-top-color: rgb(250, 209, 5);
}

.google-login.loading {
    color: transparent;
}

.google-spinner {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top: 2px solid rgb(250, 209, 5);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.forgot-password-text {
    margin-top: 10px;
    font-size: 14px;
}

.forgot-password-text a {
    color: rgb(250, 209, 5);
    text-decoration: none;
}

.forgot-password-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.forgot-password-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
}

.dark-mode .forgot-password-content {
    background-color: #2a2a2a;
    color: white;
}

.forgot-password-content h2 {
    margin-bottom: 15px;
}

.forgot-password-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.forgot-password-content button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.forgot-password-content button {
    width: 100%;
    padding: 10px;
    background-color: rgb(250, 209, 5);
    border: none;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
}

.forgot-password-content button:hover {
    background-color: rgb(198, 165, 5);
}

.forgot-password-button {
    background: none;
    border: none;
    color: rgb(250, 209, 5);
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
    font-family: inherit;
}

.forgot-password-button:hover {
    color: rgb(198, 165, 5);
}

.check-email-message {
    margin-bottom: 15px;
    font-weight: bold;
    color: rgb(250, 209, 5);
}

.install-button.login-button {
    padding: 10px 20px;
    background-color: rgb(250, 209, 5);
    border: none;
    color: white;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    margin-top: 10px;
    font-weight: bold;
}

.install-button.login-button:hover {
    background-color: rgb(198, 165, 5);
}

.dark-mode .install-button.login-button {
    background-color: rgb(250, 209, 5);
    color: black;
}

.google-warning {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    max-width: 300px;
    text-align: center;
}

.dark-mode .google-warning {
    color: #ff6b6b;
}

.dark-mode .google-spinner {
    border-color: #3a3a3a;
    border-top-color: rgb(250, 209, 5);
}

@media (max-width: 768px) {
    .accessibility-controls {
        flex-direction: column;
        align-items: flex-end;
    }

    .speech-toggle-container2 {
        margin-bottom: 10px;
    }
}
