.plan-itinerary-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.progress-bar {
  height: 100%;
  background-color: #fad105;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.back-button {
  background: none;
  border: none;
  color: #fad105;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.step-content {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.step-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

input, select, .react-datepicker-wrapper {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.location-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.location-input input {
  flex-grow: 1;
  margin-right: 10px;
  margin-bottom: 0;
}

.add-button, .remove-button {
  background-color: #fad105;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.remove-button {
  background-color: #ff6b6b;
}

.budget-input {
  display: flex;
}

.budget-input select {
  width: 30%;
  margin-right: 10px;
}

.budget-input input {
  width: 70%;
}

.interest-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.interest-buttons button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.interest-buttons button.active {
  background-color: #fad105;
  color: white;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.navigation-buttons button {
  background-color: #fad105;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.itinerary-result {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.itinerary-result textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.itinerary-text {
  white-space: pre-wrap;
  margin-bottom: 20px;
}

.itinerary-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.itinerary-actions button {
  background-color: #fad105;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.spinner2 {
  animation: spin 1s linear infinite;
}

.home-button {
  background: none;
  border: none;
  color: #fad105;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.home-button svg {
  margin-right: 5px;
}

.location-list {
  list-style-type: none;
  padding: 0;
}

.location-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
}

.location-info {
  margin-bottom: 10px;
}

.location-link {
  font-weight: bold;
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
}

.location-distance {
  font-style: italic;
  color: #666;
}

.location-description {
  margin-top: 5px;
}

.location-image-container {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.location-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.itinerary-text a {
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
}

.itinerary-text a:hover {
  text-decoration: underline;
}

.day-link {
  font-weight: bold;
}

.dark-mode .itinerary-text a {
  color: #8ab4f8;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.save-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 15px 20px;
  border-radius: 4px;
  font-size: 16px;
  animation: fadeInOut 2s ease-in-out;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.suggest-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suggest-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.suggested-locations {
  margin-top: 20px;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 4px;
}

.suggested-locations h4 {
  margin-top: 0;
}

.suggested-locations ul {
  padding-left: 20px;
}

.suggested-locations li {
  margin-bottom: 10px;
}

.location-link {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;
}


/* Dark mode styles */


.dark-mode .location-link {
  color: #8ab4f8; 
}

.dark-mode .suggested-locations {
  background-color: #333;
}

.dark-mode .suggest-button {
  background-color: #4CAF50;
  color: #fff;
}

.dark-mode .suggest-button:disabled {
  background-color: #555;
  color: #888;
}

.dark-mode .save-message {
  background-color: #4CAF50;
  color: #fff;
}


.dark-mode .plan-itinerary-container {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .progress-bar-container {
  background-color: #333;
}

.dark-mode .back-button,
.dark-mode .home-button {
  color: #ffd700;
}

.dark-mode .step-content,
.dark-mode .itinerary-result {
  background-color: #2a2a2a;
}

.dark-mode input,
.dark-mode select,
.dark-mode textarea {
  background-color: #333;
  color: #fff;
  border-color: #444;
}

.dark-mode .add-button,
.dark-mode .remove-button,
.dark-mode .navigation-buttons button,
.dark-mode .itinerary-actions button {
  background-color: #ffd700;
  color: #000;
}

.dark-mode .interest-buttons button {
  background-color: #333;
  color: #fff;
  border-color: #555;
}

.dark-mode .interest-buttons button.active {
  background-color: #ffd700;
  color: #000;
}

.dark-mode .suggested-locations {
  background-color: #333;
}

.dark-mode .suggest-button {
  background-color: #4CAF50;
  color: #fff;
}

.dark-mode .suggest-button:disabled {
  background-color: #555;
  color: #888;
}

.dark-mode .save-message {
  background-color: #4CAF50;
  color: #fff;
}