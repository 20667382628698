.explore-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .explore-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #fad105;
    display: flex;
    align-items: center;
  }
  
  .location-search {
    margin-bottom: 20px;
  }
  
  .suggested-places {
    margin-top: 20px;
  }
  
  .place-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .place-card {
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .place-card:hover {
    transform: scale(1.05);
  }
  
  .place-image {
    height: 150px;
    background-size: cover;
    background-position: center;
  }
  
  .place-card p {
    padding: 10px;
    margin: 0;
    text-align: center;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .vr-button {
    background-color: #fad105;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .loading1-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .loading1-icon {
    font-size: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .location-info {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .info-section {
    margin-bottom: 20px;
  }
  
  .info-section h3 {
    margin-bottom: 10px;
  }
  
  .info-section ul {
    padding-left: 20px;
  }
  
  .vr-chat {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .message.user {
    background-color: #e6f2ff;
    text-align: right;
  }
  
  .message.ai {
    background-color: #fff;
  }
  
  .chat-input {
    display: flex;
    margin-top: 10px;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  
  .chat-input button {
    background-color: #fad105;
    color: #000;
    border: none;
    margin-bottom: 15px;
    margin-left: 5px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Dark mode styles */
  .dark-mode .explore-container {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .dark-mode .place-card,
  .dark-mode .location-info,
  .dark-mode .vr-chat {
    background-color: #2a2a2a;
  }
  
  .dark-mode .popup-content {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .dark-mode .message.user {
    background-color: #2c3e50;
    color: #fff;
  }
  
  .dark-mode .message.ai {
    background-color: #34495e;
    color: #fff;
  }
  
  .dark-mode .chat-input input {
    background-color: #3a3a3a;
    color: #fff;
    border-color: #4a4a4a;
  }
  
  .dark-mode .vr-button,
  .dark-mode .chat-input button {
    background-color: #fad105;
    color: #000;
  }

  .dark-mode .location-search .react-select__control {
    background-color: #3a3a3a;
    border-color: #4a4a4a;
  }
  
  .dark-mode .location-search .react-select__single-value {
    color: #fff;
  }
  
  .dark-mode .location-search .react-select__input {
    color: #fff;
  }
  
  .dark-mode .location-search .react-select__menu {
    background-color: #2a2a2a;
  }
  
  .dark-mode .location-search .react-select__option {
    background-color: #2a2a2a;
    color: #fff;
  }
  
  .dark-mode .location-search .react-select__option--is-focused {
    background-color: #4a4a4a;
  }

  .dark-mode .location-search .react-select__control {
    background-color: #3a3a3a;
    border-color: #4a4a4a;
  }
  
  .dark-mode .location-search .react-select__single-value,
  .dark-mode .location-search .react-select__input-container {
    color: white;
  }
  
  .dark-mode .location-search .react-select__placeholder {
    color: #aaa;
  }
  
  .dark-mode .popup-content {
    background-color: #2a2a2a;
    color: white;
  }
  
  .dark-mode .close-button {
    color: white;
  }