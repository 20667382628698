.tools-container {
    padding: 20px;
}

.tool-box {
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
}

.tool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: #f8f8f8;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

.eco-travel-tips {
    font-size: 16px;
    line-height: 1.6;
}

.eco-travel-tips h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fad105;
}

.eco-travel-tips h4 {
    margin-top: 15px;
    margin-bottom: 5px;
    color: #fad105;
}

.eco-travel-tips ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.eco-travel-tips li {
    margin-bottom: 5px;
}


.tool-content {
    padding: 15px;
}

.tool-content input,
.tool-content select,
.tool-content button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.dark-mode .tool-content input,
.dark-mode .tool-content select,
.dark-mode .tool-content button {
    background-color: #2a2a2a;
    color: #fad105;
    border-color: #3a3a3a;
}

.tool-content button {
    background-color: #fad105;
    color: #000000;
    border: none;
    cursor: pointer;
}

.tool-content button:hover {
    opacity: 0.8;
}

.back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #fad105;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
}

.back-button svg {
    margin-right: 10px;
}

.dark-mode .back-button {
    color: #fad105;
}


.dark-mode .eco-travel-tips h3,
.dark-mode .eco-travel-tips h4 {
    color: #fad105;
}

.dark-mode .tool-header {
    background-color: #2a2a2a;
    color: #fad105;
}

.carbon-footprint-result {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.carbon-footprint-result h3 {
    margin-top: 0;
}

.dark-mode .carbon-footprint-result {
    background-color: #2a2a2a;
    color: #ffffff;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

