.post-emergency-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.emergency-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-emergency-button {
    background-color: #fad105;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
}

.emergency-form {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.emergency-form input,
.emergency-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.emergency-form textarea {
    height: 100px;
    resize: vertical;
}

.emergency-form button {
    background-color: #fad105;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.posted-emergencies {
    display: grid;
    gap: 20px;
}

.emergency-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ai-suggestion {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
}

.chat-section {
    margin-top: 20px;
}

.chat-section input {
    width: 94%;
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
}

.back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fad105;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    font-weight: bold;
}

.ai-suggestion ul {
    padding-left: 20px;
    margin: 0;
}

.ai-suggestion li {
    margin-bottom: 5px;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #fad105;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.chat-messages {
    max-height: 500px;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin-bottom: 10px;
}

.message {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}

.message.user {
    background-color: #e6f2ff;
    text-align: right;
}

.message.ai {
    background-color: #f0f0f0;
}

/* Dark mode styles */
.dark-mode .post-emergency-container {
    background-color: #1a1a1a;
    color: #fff;
}

.dark-mode .emergency-form,
.dark-mode .emergency-card,
.dark-mode .ai-suggestion {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dark-mode .emergency-form input,
.dark-mode .emergency-form textarea,
.dark-mode .chat-section input {
    background-color: #3a3a3a;
    color: #fff;
    border-color: #4a4a4a;
}

.dark-mode .emergency-form button {
    background-color: #fad105;
    color: #000;
}

.dark-mode .back-button {
    color: #fad105;
}

.dark-mode .message.user {
    background-color: #2c3e50;
    color: #fff;
}

.dark-mode .message.ai {
    background-color: #34495e;
    color: #fff;
}

.dark-mode .chat-section {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.phone-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}

.received-messages-container {
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.toggle-messages-btn {
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggle-messages-btn:hover {
    background-color: #e0e0e0;
}

.received-messages {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
}

.received-messages .message {
    margin-bottom: 10px;
    padding: 5px;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.chat-messages {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}

.message {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}

.message.user {
    background-color: #e6f2ff;
    text-align: right;
}

.message.ai {
    background-color: #f0f0f0;
}

/*dark mode styles*/
.dark-mode .received-messages-container {
    border-color: #444;
}

.dark-mode .toggle-messages-btn {
    background-color: #333;
    color: #fff;
}

.dark-mode .toggle-messages-btn:hover {
    background-color: #444;
}

.dark-mode .received-messages {
    background-color: #222;
}

.dark-mode .received-messages .message {
    background-color: #333;
    color: #fff;
}

.dark-mode .chat-messages {
    border-color: #444;
    background-color: #222;
}

.dark-mode .message.user {
    background-color: #2c3e50;
    color: #fff;
}

.dark-mode .message.ai {
    background-color: #34495e;
    color: #fff;
}

.message-with-avatar {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.message-with-avatar .react-nice-avatar {
    margin-right: 10px;
    flex-shrink: 0;
}

.message-content {
    flex-grow: 1;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 5px 10px;
}

.processing-message {
    font-style: italic;
    color: #888;
}

.dark-mode .message-content {
    background-color: #333;
    color: #fff;
}

.received-messages {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
}

.dark-mode .received-messages {
    background-color: #222;
}


.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}


.delete-btn {
    background-color: #ff4d4d;
    color: white;
}

.delete-btn:hover {
    background-color: #ff3333;
}


.chat-ai-btn {
    background-color: #fad105;
    color: black;
    margin-left: 10px;
}



.send-btn {
    background-color: #fad105;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
}



/* Dark mode styles */
.dark-mode .btn {
    border: 1px solid #555;
}

.dark-mode .delete-btn {
    background-color: #d32f2f;
}

.dark-mode .delete-btn:hover {
    background-color: #b71c1c;
}

.dark-mode .chat-ai-btn {
    background-color: #fad105;
}



.dark-mode .send-btn {
    background-color: #fad105;
}

