.profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .profile-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    position: relative;
    padding-top: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .dark-mode .profile-box {
    background-color: #2a2a2a;
    color: white;
  }
  
  .close-button1 {
    position: absolute;
    top: -30px;
    right: 0px;
    background-color: #fad105;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 40px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    color: black;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .close-button1:hover {
    background-color: #e0bc04;
  }
  
  .dark-mode .close-button1 {
    background-color: #3a3a3a;
    color: white;
  }
  
  .dark-mode .close-button:hover {
    background-color: #4a4a4a;
  }
  
  .avatar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .profile-details {
    text-align: center;
  }
  
  .profile-details h2 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .dark-mode .profile-details h2 {
    color: #fff;
  }
  
  .profile-details p {
    margin: 5px 0;
    color: #666;
  }

  .profile-details form {
    margin-top: 20px;
  }
  
  .profile-details input[type="password"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .dark-mode .profile-details input[type="password"] {
    background-color: #3a3a3a;
    color: white;
    border: 1px solid #4a4a4a;
  }
  
  .error-message {
    color: #ff0000;
    margin-bottom: 10px;
  }
  
  .dark-mode .error-message {
    color: #ff6666;
  }
  
  .dark-mode .profile-details p {
    color: #ccc;
  }
  
  .profile-details input {
    width: 60%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .dark-mode .profile-details input {
    background-color: #3a3a3a;
    color: white;
    border: 1px solid #4a4a4a;
  }
  
  .profile-box button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fad105;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  .profile-box button:hover {
    background-color: #e0bc04;
  }
  
  .dark-mode .profile-box button {
    background-color: #fad105;
    color: #333;
  }
  
  .dark-mode .profile-box button:hover {
    background-color: #e0bc04;
  }
  
  @media (max-width: 600px) {
    .profile-box {
      width: 90%;
      padding: 15px;
    }
  
    .profile-details input {
      font-size: 14px;
    }
  
    .profile-box button {
      font-size: 14px;
    }
  }