.social-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
}

.social-header {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #ffffff;
    color: #000000;
}

.back-button {
    background: none;
    border: none;
    color: #fad105;
    font-size: 20px;
    cursor: pointer;
    margin-right: 5px;
    margin-top: 22px;
}

.social-header h2 {
    margin: 0;
}

.tabs {
    display: flex;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
}

.tab {
    flex: 1;
    padding: 15px;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #fad105;
    color: #ffffff;
}

.tab-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

/* Dark mode styles */
.dark-mode .social-container {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-mode .social-header {
    background-color: #2a2a2a;
    color: #ffffff
}

.dark-mode .tabs {
    background-color: #2a2a2a;
    border-bottom-color: #3a3a3a;
}

.dark-mode .tab {
    color: #ffffff;
}

.dark-mode .tab.active {
    background-color: #fad105;
    color: #1a1a1a;
}