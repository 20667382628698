.saved-itinerary-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    position: relative;
  }

  .emergency-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #fad105;
    display: flex;
    align-items: center;
  }
  
  .back-button svg {
    margin-right: 5px;
  }
  
  .saved-itinerary-container h2 {
    color: #fad105;
    margin: 0;
  }
  
  .saved-itinerary-container h2 {
    color: #fad105;
    margin-bottom: 20px;
  }
  
  .itinerary-popup button.delete-button {
    background-color: #ff4d4d;
  }
  
  .itinerary-popup button.delete-button:hover {
    background-color: #ff3333;
  }
  
  .filter-container {
    margin-bottom: 20px;
  }
  
  .filter-container button {
    background-color: #fad105;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .itinerary-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .itinerary-item {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .itinerary-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .itinerary-item h3 {
    margin: 0 0 10px 0;
    color: #333;
  }
  
  .itinerary-item p {
    margin: 0;
    color: #666;
  }
  
  .itinerary-item.confirmed {
    border: 2px solid #4CAF50;
  }
  
  .confirmed-icon {
    color: #4CAF50;
    float: right;
  }
  
  .itinerary-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .itinerary-popup h3 {
    color: #fad105;
    margin-top: 0;
  }
  
  .itinerary-content {
    margin-bottom: 20px;
    white-space: pre-wrap;
  }
  
  .itinerary-popup button {
    background-color: #fad105;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .trip-info {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .trip-info h4 {
    margin-top: 0;
    color: #333;
  }
  

  .trip-info {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .trip-info h4 {
    margin-top: 0;
    color: #333;
  }
  
  .trip-info pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .loading1-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3000;
  }
  
  .loading1-overlay .spinner {
    color: #fad105;
    font-size: 48px;
  }
  
  .loading1-overlay p {
    color: #fff;
    margin-top: 10px;
  }
  
  .trip-info-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .trip-info-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .trip-info-content h4 {
    color: #fad105;
    margin-top: 0;
  }
  
  .trip-info-content a {
    color: #4FC3F7; 
    text-decoration: none;
  }
  
  .trip-info-content a:hover {
    text-decoration: underline;
  }
  
  .trip-info-content button {
    background-color: #fad105;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .added-to-calendar-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4CAF50;
    color: white;
    padding: 15px 25px;
    border-radius: 4px;
    z-index: 3000;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 3s ease-in-out;
  }
  

  
  /* Dark mode styles */
  .dark-mode .saved-itinerary-container {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .dark-mode .itinerary-item {
    background-color: #2a2a2a;
    color: #fff;
  }
  
  .dark-mode .itinerary-popup {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .dark-mode .trip-info {
    background-color: #2a2a2a;
  }
  
  .dark-mode .itinerary-item h3,
  .dark-mode .itinerary-item p,
  .dark-mode .trip-info h4 {
    color: #fff;
  }
  
  .dark-mode .filter-container button,
  .dark-mode .itinerary-popup button {
    background-color: #fad105;
    color: #000;
  }

  .dark-mode .trip-info {
    background-color: #2a2a2a;
    color: #fff;
  }
  
  .dark-mode .trip-info h4 {
    color: #fad105;
  }

  .dark-mode .trip-info-content {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .dark-mode .trip-info-content h4 {
    color: #fad105;
  }
  
  .dark-mode .trip-info-content a {
    color: #81D4FA; 
  }
  
  .dark-mode .trip-info-content button {
    background-color: #fad105;
    color: #000;
  }


.google-warning2 {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.dark-mode .google-warning2 {
  color: #ff6b6b;
}


  @media screen and (max-width: 768px) {
    .itinerary-popup {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }
  
    .itinerary-popup h3 {
      font-size: 1.5em;
      margin-bottom: 15px;
    }
  
    .itinerary-content {
      flex-grow: 1;
      overflow-y: auto;
    }
  
    .itinerary-popup button {
      width: 70%;
      margin-bottom: 10px;
      margin-right: 0;
      padding: 15px;
      font-size: 18px;
      margin-top: 10px;
    }
  }