.connect-container {
  padding: 20px;
}

.your-post-box {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.your-post-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.your-post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.your-post-header h3 {
  margin: 0;
}

.add-post-icon {
  font-size: 20px;
  color: #fad105;
}

.click-to-post {
  margin-top: 10px;
  text-align: center;
  color: #888;
}

.your-post-content {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.post-form {
  margin-bottom: 10px;
}

.post-form textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.post-form-buttons {
  display: flex;
  justify-content: flex-end;
}

.post-form-buttons button {
  margin-left: 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.post-form-buttons button:first-child {
  background-color: #ccc;
}

.post-form-buttons button:last-child {
  background-color: #fad105;
}

.your-active-post {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.your-active-post p {
  margin: 0 0 10px 0;
}

.delete-post-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.people-around {
  margin-top: 30px;
}

.people-around h3 {
  margin-bottom: 15px;
}

.user-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.user-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-card-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-card h4 {
  margin: 0 0 5px 0;
}

.user-card p {
  text-align: center;
  margin: 0 0 10px 0;
}

.add-friend-button {
  background-color: #fad105;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-friend-button svg {
  font-size: 16px;
}

.your-active-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.your-active-post .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.your-active-post .user-avatar {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
}

.your-active-post h4 {
  margin: 0;
}

.your-active-post p {
  margin: 10px 0;
}

.user-type-toggle {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-label {
  margin: 0 10px;
  font-weight: bold;
  color: #888;
  transition: color 0.3s ease;
}

.toggle-label.active {
  color: #333;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fad105;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fad105;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.user-rating {
  margin-bottom: 10px;
}


.rating-container {
  display: flex;
  align-items: center;
}

.number-of-ratings {
  margin-left: 8px;
  font-size: 0.875rem;
  color: #666;
}


.people-around-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-type-filter {
  display: flex;
  align-items: center;
}

.user-type-filter select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;
}

.user-type-filter svg {
  color: #888;
}

.friend-status {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: bold;
}

.friend-status.accepted {
  background-color: #4CAF50;
  color: white;
}

.friend-status.pending {
  background-color: #FFC107;
  color: black;
}

/* Dark mode styles */
.dark-mode .connect-container {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .your-post-box,
.dark-mode .your-post-content,
.dark-mode .user-card {
  background-color: #2a2a2a;
  border-color: #3a3a3a;
}

.dark-mode .post-form textarea {
  background-color: #3a3a3a;
  color: #ffffff;
  border-color: #4a4a4a;
}

.dark-mode .add-friend-button,
.dark-mode .post-form-buttons button:last-child {
  background-color: #fad105;
  color: #000000;
}

.dark-mode .delete-post-button {
  background-color: #8b0000;
}

.dark-mode .your-active-post {
  background-color: #2a2a2a;
  border-color: #3a3a3a;
  color: #ffffff;
}

.dark-mode .toggle-label {
  color: #888;
}

.dark-mode .toggle-label.active {
  color: #fff;
}

.dark-mode .slider {
  background-color: #555;
}

.dark-mode input:checked + .slider {
  background-color: #fad105;
}

.dark-mode .slider:before {
  background-color: #333;
}

.dark-mode .user-type-filter select {
  background-color: #2a2a2a;
  color: #ffffff;
  border-color: #3a3a3a;
}

.dark-mode .user-type-filter svg {
  color: #aaa;
}

.dark-mode .click-to-post {
  color: #aaa;
}