.members-emergency-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.emergency-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fad105;
}

.emergency-list {
    display: grid;
    gap: 20px;
}

.emergency-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.user-name {
    font-weight: bold;
    margin-left: 10px;
}

.message-form {
    display: flex;
    margin-top: 20px;
}

.message-form input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.message-form button {
    background-color: #fad105;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

/* Dark mode styles */
.dark-mode .members-emergency-container {
    background-color: #1a1a1a;
    color: #fff;
}

.dark-mode .emergency-card {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dark-mode .message-form input {
    background-color: #3a3a3a;
    color: #fff;
    border-color: #4a4a4a;
}

.dark-mode .message-form button {
    background-color: #fad105;
    color: #000;
}

.dark-mode .emergency-card a {
    color: #8ab4f8; 
    text-decoration: none; 
}

.dark-mode .emergency-card a:hover {
    text-decoration: underline; 
    color: #adc7ff;
}