

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


.Signup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.Signup.dark-mode {
  background-color: #1a1a1a;
  color: #f0f0f0;
}

.Signup-header {
  text-align: center;
  width: 100%;
  max-width: 550px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.Signup.dark-mode .Signup-header {
  background-color: #2a2a2a;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.SignupTitleContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.Title {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  color: rgb(250, 209, 5);
  margin: 0;
  margin-left: 4px;
  margin-bottom: 10px;
}

.google-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin-left: auto;  
  margin-right: auto;
  margin-top: 10px;
}

.terms-container {
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}

.terms-container label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.terms-container input[type="checkbox"] {
  margin-right: 10px;
  width: auto;
}

.link {
  color: rgb(250, 209, 5);
  text-decoration: underline;
  cursor: pointer;
  margin: 0 5px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.Signup.dark-mode .Modal {
  background-color: #2a2a2a;
  color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1), 0 1px 3px rgba(255, 255, 255, 0.08);
}

.modal-content {
  text-align: left;
}

.modal-content h2 {
  margin-top: 0;
  color: inherit;
}

.modal-content p {
  color: inherit;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(250, 209, 5);
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Signup.dark-mode .modal-content button {
  background-color: rgb(198, 165, 5);
}

.link {
  color: rgb(250, 209, 5);
  text-decoration: underline;
  cursor: pointer;
  margin: 0 5px;
  transition: color 0.3s;
}

.Signup.dark-mode .link {
  color: rgb(255, 215, 0);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessibility-controls3 {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
}


.scrollable-content {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 20px;
}

.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Signup.dark-mode .scrollable-content::-webkit-scrollbar-track {
  background: #333;
}

.Signup.dark-mode .scrollable-content::-webkit-scrollbar-thumb {
  background: #666;
}

.Signup.dark-mode .scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.Signup.dark-mode .google-signup {
  background-color: #3a3a3a;
  border-color: #555;
}

.google-logo {
  width: 20px;
  margin-right: 10px;
}

.google-signup:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.google-signup span {
  position: relative;
  z-index: 1;
  color: black;
}

.Signup.dark-mode .google-signup span {
  color: #f0f0f0;
}

.separator1 {
  width: 100%;
  max-width: 300px;
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0; 
  margin-left: auto;  
  margin-right: auto
}

.Signup.dark-mode .separator {
  border-top-color: #555;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form div {
  margin-bottom: 10px;
  width: 100%;
}

form input, form select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.Signup.dark-mode form input,
.Signup.dark-mode form select {
  background-color: #3a3a3a;
  color: #f0f0f0;
  border-color: #555;
}

form button {
  padding: 10px 20px;
  background-color: rgb(250, 209, 5);
  border: none;
  color: white;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

form button:hover {
  background-color: rgb(198, 165, 5);
}

.login-text {
  margin-top: 20px;
}

.login-text a {
  color: rgb(250, 209, 5);
  text-decoration: none;
}
.speech-toggle3 {
  font-size: 24px;
  cursor: pointer;
  color: #fad105;
  margin-right: 60px;
  margin-top: 21px;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-container input {
  flex: 1;
  padding-right: 30px;
}

.input-container input.error {
  border: 2px solid red;
}

.input-container input.success {
  border: 2px solid green;
}

.validation-message {
  position: relative;
  left: 0;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.validation-error {
  color: red;
  display: flex;
  align-items: center;
}

.validation-warning {
  color: orange;
  display: flex;
  align-items: center;
}
.input-container {
  transition: margin-bottom 0.3s ease;
}

.validation-error::before {
  content: '✖';
  margin-right: 5px;
}


.validation-warning::before {
  content: '⚠';
  margin-right: 5px;
}

.password-info {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar-container > div {
  border-radius: 50%;
  overflow: hidden;
}

select#gender {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #fdfdfd;
  border-radius: 4px;
  font-size: 16px;
}

.Signup {
  transition: opacity 0.5s ease-out;
}

.Signup.success .Signup-header > *:not(.success-message) {
  display: none;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-text {
  font-size: 24px;
  text-align: center;
  animation: flyIn 0.5s ease-out forwards;
  opacity: 0;
  transform: translateY(50px);
}

.verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.verification-code-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.verification-code-container input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.verification-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(250, 209, 5);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.verification-form button:hover {
  background-color: rgb(198, 165, 5);
}

@keyframes flyIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid rgb(250, 209, 5);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.verified-text {
  color: rgb(250, 209, 5);
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.theme-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #fad105;
  transition: color 0.3s;
}

.Signup.dark-mode .theme-toggle {
  color: #fad105;
}

@media (max-height: 600px) {
  .Signup {
    height: auto;
    min-height: 100vh;
  }

  form {
    max-height: none;
  }
}

.accessibility-controls3 {
  position: absolute;
  top: 2px;
  right: 0px;
  display: flex;
  align-items: center;
}



.speech-toggle3.active {
  color: #00ff00;
}