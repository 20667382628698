.chats-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.search-bar {
    display: flex;
    padding: 10px;
    background-color: #f0f0f0;
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 16px;
}

.search-bar button {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #fad105;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.chats-list {
    flex: 1;
    overflow-y: auto;
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    position: relative;
}

.new-message-dot {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

.chat-item:hover {
    background-color: #f9f9f9;
}

.chat-info {
    margin-left: 15px;
}

.chat-info h4 {
    margin: 0;
    font-size: 16px;
}

.chat-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #777;
}

.chat-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.chat-content {
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a2a2a;
    color: white;
    position: relative; 
    width: 100%;
    height: 60px;
    padding: 5px 10px;
}

.chat-header-info {
    display: flex;
    align-items: center;
    position: absolute; 
    left: 50%; 
    transform: translateX(-50%); 
}

.chat-header-info h3 {
    margin-left: 10px;
    font-size: 16px;
    margin: 0 0 0 10px;
}

.chat-header button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 60px;;
}

.message {
    max-width: 70%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-right: 12px;
}

.message.sent {
    background-color: #dcf8c6;
    align-self: flex-end;
}

.message.received {
    background-color: #f0f0f0;
    align-self: flex-start;
}

.message-input {
    display: flex;
    background-color: #f0f0f0;
    width: 100%;
    padding: 10px 5px;
    margin-bottom: 10px;
}

.message-input input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 8px;
    font-size: 14px;
}

.message-input button {
    margin-left: 10px;
    background-color: #fad105;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    padding: 8px;
    min-width: 40px;
}

.search-results {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 20%;
    margin-top: 70px;
    z-index: 1000;
}

.search-results-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.search-results-content {
    background-color: white;
    border-radius: 8px;
    width: 50%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
}

.search-results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.search-results-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    top:39%;
    right: 50%;
}

.search-results-list {
    overflow-y: auto;
    padding: 15px;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item span {
    margin-left: 10px;
    flex-grow: 1;
}

.search-result-item button {
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
}

.search-result-item button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


.pending-requests {
    margin-top: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.pending-requests h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.pending-request-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pending-request-item span {
    margin-left: 10px;
    flex-grow: 1;
}

.pending-request-item button {
    margin-left: 5px;
    padding: 5px 10px;
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pending-request-item button:last-child {
    background-color: #ff6b6b;
}

.search-result-item button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Dark mode styles */
.dark-mode .chats-container {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-mode .search-bar,
.dark-mode .message-input {
    background-color: #2a2a2a;
}

.dark-mode .search-bar input,
.dark-mode .message-input input {
    background-color: #3a3a3a;
    color: #ffffff;
    border-color: #4a4a4a;
}

.dark-mode .chat-item {
    border-bottom-color: #3a3a3a;
}

.dark-mode .chat-item:hover {
    background-color: #2a2a2a;
}

.dark-mode .chat-content {
    background-color: #1a1a1a;
}

.dark-mode .chat-header {
    background-color: #2a2a2a;
}

.dark-mode .chat-header button {
    color: white;
}

.dark-mode .messages-container {
    background-color: #1a1a1a;
}

.dark-mode .message.sent {
    background-color: #baa800;
}

.dark-mode .message.received {
    background-color: #2a2a2a;
}

.dark-mode .search-results {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dark-mode .search-result-item {
    border-bottom-color: #3a3a3a;
}

.dark-mode .search-result-item button {
    background-color: #3a3a3a;
}

.dark-mode .pending-requests {
    background-color: #2a2a2a;
}

.dark-mode .pending-request-item button:last-child {
    background-color: #8b0000;
}

.dark-mode .search-result-item button:disabled {
    background-color: #555555;
}

.dark-mode .search-results-content {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-mode .search-results-header {
    border-bottom-color: #3a3a3a;
}

.dark-mode .search-result-item {
    border-bottom-color: #3a3a3a;
}

.dark-mode .close-button {
    color: #ffffff;
}

.star-rating {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.star-rating svg {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 767px) {
    .chat-header-info {
        flex-wrap: wrap; 
        justify-content: center;
    }

    .chat-header-info h3 {
        font-size: 14px;
        margin: 5px 0;
    }

    .star-rating {
        margin-left: 5px;
    }

    .star-rating svg {
        width: 16px;
        height: 16px;
    }
}


@media screen and (max-width: 767px) {
    .chat-popup {
      padding: 0;
    }
  
    .chat-content {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  
    .chat-header {
      border-radius: 0;
    }
  
    .messages-container {
      padding: 10px;
    }
  
    .message {
      max-width: 80%;
    }
  
    .message-input {
      padding: 10px;
    }
  
    .message-input1 input {
      font-size: 14px;
    }
  
    .message-input button {
      padding: 8px 12px;
    }

    .chat-info h4 {
        font-size: 14px;
    }

    .chat-info p {
        font-size: 12px;
    }
  }