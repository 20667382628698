.journal-container {
    padding: 20px;
}

.add-journal {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-journal h3 {
    margin: 0;
}

.add-journal-button {
    background-color: #fad105;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.journal-form {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.saving-button {
    background-color: #cccccc;
    cursor: not-allowed;
}

.journal-form input,
.journal-form textarea {
    
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
}

.journal-form textarea {
    height: 150px;
    resize: vertical;
}

.journal-form-buttons {
    display: flex;
    justify-content: flex-end;
}

.journal-form-buttons button {
    margin-left: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.journal-form-buttons button:first-child {
    background-color: #ccc;
}

.journal-form-buttons button:last-child {
    background-color: #fad105;
}

.saved-journals {
    margin-top: 30px;
}

.saved-journals h3 {
    margin-bottom: 15px;
}

.journal-entry {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.journal-entry:hover {
    background-color: #f5f5f5;
}

.journal-entry h4 {
    margin: 0 0 5px 0;
}

.journal-entry p {
    margin: 0;
    color: #666;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.popup-content h3 {
    margin-top: 0;
}

.popup-content button {
    margin-top: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fad105;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-icon {
    font-size: 40px;
    color: #fad105;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    background-color: #fad105;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-controls span {
    font-weight: bold;
}


.popup-content {
    display: flex;
    flex-direction: column;
}

.popup-content p {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 20px;
}


/* Dark mode styles */
.dark-mode .pagination-controls button {
    background-color: #fad105;
    color: #000000;
}

.dark-mode .pagination-controls button:disabled {
    background-color: #4a4a4a;
    color: #888888;
}

.dark-mode .journal-container {
    background-color: #1a1a1a;
    color: #ffffff;
}

.dark-mode .add-journal,
.dark-mode .journal-form,
.dark-mode .journal-entry,
.dark-mode .popup-content {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dark-mode .journal-form input,
.dark-mode .journal-form textarea {
    background-color: #3a3a3a;
    color: #ffffff;
    border-color: #4a4a4a;
}

.dark-mode .add-journal-button,
.dark-mode .journal-form-buttons button:last-child,
.dark-mode .popup-content button {
    background-color: #fad105;
    color: #000000;
}

.dark-mode .journal-entry:hover {
    background-color: #3a3a3a;
}

.dark-mode .journal-entry p {
    color: #aaa;
}

.attached-images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    margin-bottom: 15px;
}

.image-thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
}

.image-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.image-thumbnail button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.journal-form-buttons label.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #E9E9ED;
    color: #000;
}

.journal-form-buttons label.button:hover {
    background-color: #ccc;
}

.journal-entry-images {
    display: flex;
    margin-top: 10px;
}

.journal-entry-images img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 5px;
    border-radius: 4px;
}

.journal-entry-images span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 12px;
}

.popup-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.popup-images img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.image-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .image-preview-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .image-preview-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .delete-button {
    background-color: #ff4136;
    color: white;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .popup-buttons button {
    margin: 0 5px;
  }

  @media (max-width: 768px) {
    .journal-form-buttons {
      flex-direction: column;
      align-items: stretch;
    }
  
    .journal-form-buttons button,
    .journal-form-buttons label.button {
      margin: 5px 0;
      width: 100%;
    }

    .attached-images-grid {
        grid-template-columns: repeat(3, 1fr);
      }
    
      .image-thumbnail {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
      }

      .popup-content {
        width: 90%;
        max-height: 90%;
        padding: 15px;
      }
    
      .popup-images img {
        width: calc(33.33% - 10px);
        height: auto;
      }
      .add-journal {
        padding: 20px;
      }
    
      .add-journal-button {
        width: 40px;
        height: 40px;
        font-size: 24px;
      }
      .journal-container {
        padding: 10px;
      }
  }