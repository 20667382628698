@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.Home {
    min-height: 100vh;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.Home.dark-mode {
    background-color: #1a1a1a;
    color: #ffffff;
}

.Home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    transition: background-color 0.3s ease;
}

.dark-mode .Home-header {
    background-color: #2a2a2a;
}

.header-right {
    display: flex;
    align-items: center;
}

.theme-toggle1 {
    font-size: 24px;
    cursor: pointer;
    margin-right: 20px;
    color: #fad105;
}
.speech-toggle1 {
    font-size: 24px;
    cursor: pointer;
    margin-right: 20px;
    color: #fad105;
}

.speech-toggle1.active {
    color: #00ff00;
  }

.Title {
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    color: rgb(250, 209, 5);
    margin: 0;
}

.profile-container {
    position: relative;
}

.avatar-wrapper {
    cursor: pointer;
}

.dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
    width: 120px;
}

.dark-mode .dropdown {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.dropdown button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
}

.captured-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
}

.captured-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.captured-image-container .loading-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.location-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.location-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark-mode .location-popup-content {
  background-color: #2a2a2a;
  color: white;
}

.location-popup-content button {
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.location-popup-content label {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.location-popup-content input[type="checkbox"] {
  margin-right: 5px;
}

.dark-mode .dropdown button {
    color: #ffffff;
}

.dropdown button:hover {
    background-color: #f0f0f0;
}

.dark-mode .dropdown button:hover {
    background-color: #3a3a3a;
}

.section-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 20px;
}

.section-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dark-mode .section-box {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
}

.section-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.dark-mode .section-box:hover {
    box-shadow: 0 5px 15px rgba(255,255,255,0.1);
}

.section-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: #fad105;
    transition: all 0.3s ease;
}

.section-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.dark-mode .section-name {
    color: #ffffff;
}

.location-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.location-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  text-align: center;
}

.dark-mode .location-popup-content {
  background-color: #2a2a2a;
  color: white;
}

.location-popup-content button {
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.section-box:nth-child(1):hover .section-icon {
    animation: none;
}


.plan-itinerary .section-icon {
    transition: opacity 0.2s ease-in-out;
}

.section-box:nth-child(2):hover .section-icon {
    animation: rotate 2s linear infinite;
}

.section-box:nth-child(3):hover .section-icon {
    animation: swing 1s ease-in-out infinite;
}

.section-box:nth-child(4):hover .section-icon {
    animation: bounce 0.5s infinite alternate;
}

.section-box:nth-child(5):hover .section-icon {
    animation: messageBubbles 1.5s ease-in-out infinite;
}

.section-box:nth-child(6):hover .section-icon {
    animation: writing 1s infinite;
}

.section-box:nth-child(7):hover .section-icon {
    animation: pulse 1s infinite;
}

.section-box:nth-child(8):hover .section-icon {
    animation: shake 0.5s infinite;
}

/* Keyframe animations */
@keyframes bounce {
    from { transform: translateY(0); }
    to { transform: translateY(-10px); }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

@keyframes rotate {
    0% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
      transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
      -webkit-transform: rotate3d(0, 0, 1, 180deg);
      transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
      -webkit-transform: rotate3d(0, 0, 1, 270deg);
      transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 0, 1, 360deg);
    }
}

@keyframes swing {
    20% {
      transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
      transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
      transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
      transform: rotate3d(0, 0, 1, -5deg);
    }
    to {
      transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes messageBubbles {
    0%, 100% { transform: scale(1); }
    33% { transform: scale(1.1) translate(-3px, -3px); }
    66% { transform: scale(1.1) translate(3px, -3px); }
}

@keyframes writing {
    0% { transform: translate(0, 0); }
    25% { transform: translate(2px, -2px); }
    50% { transform: translate(4px, -4px); }
    75% { transform: translate(2px, -2px); }
    100% { transform: translate(0, 0); }
}

body.dark-mode {
    background-color: #1a1a1a;
    color: #ffffff;
}

.camera-container {
    position: relative;
    margin-right: 20px;
  }
  
  .camera-icon {
    font-size: 24px;
    cursor: pointer;
    color: #fad105;
  }
  
  .image-options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
    width: 180px;
    
  }
  
  .dark-mode .image-options {
    background-color: #2a2a2a;
    border-color: #3a3a3a;
  }
  
  .image-options label,
  .image-options button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .dark-mode .image-options label,
  .dark-mode .image-options button {
    color: #ffffff;
  }
  
  .image-options label:hover,
  .image-options button:hover {
    background-color: #f0f0f0;
  }
  
  .dark-mode .image-options label:hover,
  .dark-mode .image-options button:hover {
    background-color: #3a3a3a;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
  }
  
  .dark-mode .popup-content {
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
  }
  
  .loading-icon {
    font-size: 48px;
    color: #fad105;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .dark-mode .profile-box {
    background-color: #2a2a2a;
    color: white;
  }
  
  .dark-mode .profile-box input {
    background-color: #3a3a3a;
    color: white;
    border: 1px solid #4a4a4a;
  }

  @keyframes breathe {
    0% {
      transform: scale(0.9);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0.7;
    }
  }
  
  .breathing-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    animation: breathe 1.5s ease-in-out infinite;
  }

  @media (max-width: 768px) {
    .Home-header {
      flex-direction: column;
      align-items: center;
    }
  
    .header-right {
      margin-top: 10px;
      width: 100%;
      justify-content: space-around;
    }
  
    .Title {
      font-size: 40px;  
    }

    .speech-toggle-container{
      margin-top: 0px;
      margin-left: 10px;
    }
    .dropdown {
      right: 50%;
      transform: translateX(50%);
    }
    .camera-container {
      margin-right: 10px;
    }
    .theme-toggle1{
      margin-bottom: 2px;
    }
    
    .popup-content {
      max-width: 90%;
      max-height: 90%;
      padding: 15px;
    }
  }